.grid {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  margin: 40px 0 24px;
}

.card {
  border-radius: 8px;
  background: var(--theme-area-background);
  box-shadow: var(--theme-area-box-shadow);
  padding: 24px 24px 16px 24px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.size1,
.size2,
.size3,
.size4,
.size5,
.size6 {
  grid-column: span 1;
}

.cardTitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--theme-text-secondary);
  margin: 0 0 24px;
  display: flex;
  align-items: center;
}

.cardData {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: var(--theme-text);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 40px;
  margin: 0;
}

.cardData span:first-of-type {
  margin-right: 16px;
}

.label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 12px;
  border-radius: 6px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  display: inline-flex;
  align-items: center;
}

.label:last-of-type {
  margin-right: 0;
}

.label b {
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
}

.label.primary {
  background: rgba(0, 145, 255, 0.08);
  color: var(--color-dodger-blue);
}

.label.secondary {
  background: rgba(57, 193, 203, 0.08);
  color: var(--color-medium-turquoise);
}

.chartWrap {
  border-radius: 8px;
  background: var(--theme-area-background);
  box-shadow: var(--theme-area-box-shadow);
  margin-bottom: 40px;
}

.chartTooltip {
  background: var(--theme-area-background);
  box-shadow: var(--theme-area-box-shadow);
  padding: 24px;
  border-radius: 8px;
}

.chartTooltipTitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--theme-text-secondary);
  margin: 0 0 16px;
}

.chartTooltipData {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: var(--theme-text);
}

@media (min-width: 768px) {
  .grid {
    gap: 24px;
    grid-template-columns: repeat(6, 1fr);
    margin: 56px 0 24px;
  }

  .chartWrap {
    margin-bottom: 56px;
  }

  .size1 {
    grid-column: span 1;
  }
  .size2 {
    grid-column: span 2;
  }
  .size3 {
    grid-column: span 3;
  }
  .size4 {
    grid-column: span 4;
  }
  .size5 {
    grid-column: span 5;
  }
  .size6 {
    grid-column: span 6;
  }
}
